import React from "react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";

const getScheduleBlock = ({ games }) => {
  const formatDate = (dateString) => {
    const [month, day, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}T00:00:00`);
  };

  const today = new Date();
  const nextWeek = new Date(today);
  nextWeek.setDate(today.getDate() + 8);

  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);

  const gamesNextWeek = games.filter(game => {
    const gameDate = formatDate(game.Date);
    return gameDate >= today && gameDate <= nextWeek;
  }).sort((a, b) => formatDate(a.Date) - formatDate(b.Date));

const gamesLastWeek = games.filter(game => {
  const gameDate = formatDate(game.Date);
  return gameDate < today && gameDate >= lastWeek;
}).sort((a, b) => formatDate(a.Date) - formatDate(b.Date));

  
  
  console.log("Games for next week:", gamesNextWeek);
  console.log("Games for last week:", gamesLastWeek);


  const renderGames = (gameList) => {
    return gameList.map((game, index) => (
      <Paper
        key={game.id}
        style={{
          margin: "10px",
          padding: "10px",
          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
          borderLeft: "4px solid #9F0F2E",
          borderRadius: "0px",
          paddingTop: "15px",
          paddingBottom: "15px"
        }}
      >
      <Typography variant="h6" style={{ fontSize: "1rem" }}>
        {game.Played === "y" ? game.Score : `${game.Home} vs ${game.Away}`}
        {game.Played === "y" && (
          <Link to={game.GameLink} style={{ marginLeft: "20px" }}>
            Game Summary
          </Link>
        )}
      </Typography>
        <Typography variant="body1" style={{ fontSize: "1rem" }}>Date: {game.Date}</Typography>
        <Typography variant="body1" style={{ fontSize: "1rem" }}>Time: {game.Time}</Typography>
      </Paper>
    ));
  };

  return (
    <div>
      <Typography variant="h5" style={{ margin: "20px 0" }}>
        This Weeks Games
      </Typography>
      {renderGames(gamesLastWeek)}
      <br/>
      <Typography variant="h5" style={{ margin: "20px 0" }}>
        Upcoming Games
      </Typography>
      {renderGames(gamesNextWeek)}
    </div>
  );
};

export default getScheduleBlock;
