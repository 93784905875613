import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stats from '../../../api/royalStats.jsx';
import backGround from '../../../image/6897478.jpeg';
import background1 from '../../../image/IMG_0178.jpeg';
import CardHeader from '@mui/material/CardHeader';

const Item = styled(Paper)(({ theme }) => ({
  backgroundimage: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Royal = "https://content.justrightsteve.com/cards/pics/IMG_0172.jpeg"

export default function BasicGrid() {
  //const [expanded, setExpanded] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}
            sx={
              {
                backgroundImage: `url(${backGround})`,
              }
            }>
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12} >
          <Item>
            <Card sx={
              { 
              }
            } >
              <CardHeader sx={{ backgroundImage: `url(${background1})`, color: 'black', backGroundRepeat: "no-repeat" }}
                          title="Royal Russell | March 21, 1960 – May 17, 2021"
                          titleTypographyProps={
                            {
                              fontFamily: "cursive",
                              variant: "h5",
                             }
                          }
                          >
              </CardHeader>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={3}> 
          <Item sx={
            {
              maxWidth: 470
            }
          } >
            <Card sx={{
            }}>
              <CardMedia
                component="img"
                height="300"
                image={Royal}
                sx={
                  {
                    maxWidth: "480px"
                  }
                }
              />
              <CardContent sx={{width: 'auto'}}>
                <Typography variant="body2">
                A beloved family man, hockey player, teammate and much more, Royal played 13 seasons in the UHL.  He passed away in May of 2021. 
                </Typography>
                <Typography variant="body2" >
                  ------------------------------
                </Typography>
                <Typography variant="body2">
                Off the ice Royal was a dedicated public servant having served in the US Air Force and then the Clinton Township Fire Department.  
                </Typography>
              </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={9} sx={{
        }}>
          <Item sx={{
            maxWidth: 750
          }}  >
            <Grid container spacing={1}
                  sx={{}}>
              <Grid item xs={12} sm={12} md={12}>
                <Item sx={{ background: '#ececec' }} >
                  <Stats />
                </Item>
              </Grid>
             </Grid>
          </Item>
        </Grid>
        </Grid>
      </Box>
  );
}
