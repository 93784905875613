import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import API_ENDPOINTS from './config.js'

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function getPoints(params) {
  return (
    Number(`${params.row.W}`) * 2 + Number(`${params.row.T}`)
  )
}

const columns = [
    { field: 'Team',    width: '100', headerClassName: 'super-app-theme-header'},
    { field: 'W',       width: '10'},
    { field: 'L',       width: '10'},
    { field: 'T',       width: '10'},
    { field: 'P',       width: '10', valueGetter: getPoints }
]

//var rows = require('./json/standings.json');

export default function Content() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    // Fetch player data from the API endpoint
    setLoading(true);    
    // Fetch data from the API
    axios.get(API_ENDPOINTS.getStandings)
      .then(response => {
        setRows(response.data); // Store the fetched data in the state
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box>
    <Card>
      <CardHeader sx={{color: 'black'}}
          title="Standings"
        />
    </Card>
    <div style={{ height: 175, width: '100%'}}>
      <StripedDataGrid
          rowHeight={26}
          rows={rows}
          loading={loading}
          columns={columns}
          rowsPerPageOptions={[4]}
          getRowId={(row) => row.id}
          //getRowId={(row) => row._id.$oid}
          getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          sx={{}}
          density='compact'
          hideFooter={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'P',
                  sort: 'desc'
                }
              ]
            }
          }}
      />
    </div>      
    </Box>

  )
}