import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

function getFullName(params) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}

function getPos(params) {
  return `${params.row.seasons[0]?.Position}`
}

function getNumber(params) {
  return `${params.row.seasons[0]?.jerseyNum}`
}

function getGoals(params) {
  const goals = parseInt(params.row?.seasons?.[0]?.G)
  return !isNaN(goals) ? goals: 0
}

function getAssists(params) {
  const assists = parseInt(params.row?.seasons?.[0]?.A)
  return !isNaN(assists) ? assists: 0  
}

function getPoints(params) {
  const points = parseInt(params.row?.seasons?.[0]?.G) + parseInt(params.row?.seasons?.[0]?.A)
  return !isNaN(points) ? points: 0
}

function getWins(params) {
  const wins = parseInt(params.row?.seasons?.[0]?.W, 10);
  return !isNaN(wins) ? wins : 0;
}

function getLosses(params) {
  const losses = parseInt(params.row?.seasons?.[0]?.L, 10);
  return !isNaN(losses) ? losses : 0;
}

function getTies(params) {
  const ties = parseInt(params.row?.seasons?.[0]?.T, 10);
  return !isNaN(ties) ? ties : 0;
}

function getGoalsAgainst(params) {
  const goalsAgainst = parseInt(params.row?.seasons?.[0]?.GA, 10);
  return !isNaN(goalsAgainst) ? goalsAgainst : 0;
}

function getGoalsAgainstAverage(params) {
  const gaa = parseInt(params.row.seasons[0]?.GA) /
              parseInt(params.row.seasons[0]?.GP)
  return Number(!isNaN(gaa) ? gaa : 0).toFixed(2)
}

const columnsPlayers = [
  { field: 'Name', width: '160', valueGetter: getFullName },
  { field: 'Pos', width: '30', valueGetter: getPos },
  { field: 'jerseyNum', width: '30', valueGetter: getNumber, headerName: 'No', },
  { field: 'G', width: '30', valueGetter: getGoals },
  { field: 'A', width: '30', valueGetter: getAssists },
  { field: 'P', width: '30', valueGetter: getPoints }
]

const columnsGoalies = [
  { field: 'Name', width: '120', valueGetter: getFullName },
  { field: 'Pos', width: '30', valueGetter: getPos },
  { field: 'W', width: '30', valueGetter: getWins },
  { field: 'L', width: '30', valueGetter: getLosses },
  { field: 'T', width: '30', valueGetter: getTies },
  { field: 'GA', width: '30', valueGetter: getGoalsAgainst },
  { field: 'AVG', width: '30', valueGetter: getGoalsAgainstAverage }
]

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Content(props) {  
  const { playersData, goaliesData } = props;

  const CURRENT_SEASON = "1";  // You can adjust this value as needed

  const filterPlayers = playersData.filter(player => 
    player.seasons?.some(season => season.Team === '4' && season.id === CURRENT_SEASON)
  );
  
  const filterGoalies = goaliesData.filter(goalie => 
    goalie.seasons?.some(season => season.Team === '4' && season.id === CURRENT_SEASON)
  );
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  const [expanded, setExpanded] = React.useState(false);
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{
      maxWidth: 480
    }}>
      <CardActions>
        <Typography>
          Roster:
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ width: 'auto' }}>
          <DataGrid
            autoHeight
            rowHeight={25}
            rows={filterPlayers}
            columns={columnsPlayers}
            pageSize={12}
            rowsPerPageOptions={[12]}
            getRowId={(row) => row.id}
            sx={{}}
            components={{
              Toolbar: GridToolbar
            }}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'P',
                    sort: 'desc'
                  }
                ]
              }
            }}
          />
        </CardContent>
        <CardContent sx={{ width: 'auto' }}>
          <DataGrid
            autoHeight
            rowHeight={25}
            rows={filterGoalies}
            columns={columnsGoalies}
            pageSize={12}
            rowsPerPageOptions={[12]}
            getRowId={(row) => row.id}
            sx={{}}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'P',
                    sort: 'desc'
                  }
                ]
              }
            }}
          />
        </CardContent>
      </Collapse>
    </Card>

  )
}