import * as React from 'react';
import { styled } from '@mui/material/styles';
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Russell from './cards/players/russell';
import Girard from './cards/players/girard';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import background from '../image/IMG_0178.jpeg';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  return (
      <Grid container
            spacing={1}
            padding={1} 
            sx={
              {
                maxWidth: 1105
              }
            }
      >
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12} >
          <Item>
            <Card sx={
              {}
            } >
              <CardHeader sx={{ backgroundImage: `url(${background})`, color: 'black', backGroundRepeat: "no-repeat" }}
                          title="In Memoriam"
                          titleTypographyProps={
                            {
                              fontFamily: "cursive",
                              variant: "h5"
                             }
                          }
                          >
              </CardHeader>
              <CardContent sx={{width: 'auto'}}>
                <Typography variant="body2">
                Here we pay tribute to those who have departed.  Though they have left us, they will forever be in our hearts and memories.
                </Typography>
                <Typography variant="body2" >
                  ------------------------------
                </Typography>
                <Typography variant="body2">
                Please take a few minutes to read about our beloved brothers. 
                </Typography>
              </CardContent>
            </Card>
          </Item>
          <Item >
            <Russell />
          </Item>
          <Item >
            <Girard />
          </Item>
        </Grid>
      </Grid>
  );
}