import React, { Component } from "react";
import Navigator from "./component/Navigator";
import Box from '@mui/material/Box';

class Main extends Component {
  render() {
    return (
          <Box >
            <Navigator />
          </Box>
    );
  }
}
 
export default Main;