import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import '../css/box.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

const rows = require('./json/royalCareer.json');

//const stats = (process.env.REACT_APP_PLAYER_API);

const columns = [
    { field: 'Year', width: '85' },
    { field: 'Team', width: '73'},
    { field: 'GP', width: '1'},
    { field: 'G', width: '1'},
    { field: 'A', width: '1'},
    { field: 'P', width: '1'},
    { field: 'PIM', width: '1'}
  ]

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Content() {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // const [tableData, setTableData] = useState([])
  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");
  // useEffect(() =>{
  //   fetch(stats)
  //   .then((response) => response.json())
  //   .then((data) => setTableData(data.seasons))
  // }, [])

  // console.log(tableData)
  
  return (
    <Card raised='true'
      sx={
        {
          
        }
        }>
        <CardHeader 
        sx={{
          fontWeight: 'bold'
        }}
        titleTypographyProps={{variant:'h7' }}
        title="All Time Statistics"
        />
        <Grid container spacing={1}
          sx={{}}
          >              
          <CardContent sx={{
              width: 410
            }} >
            <DataGrid
              autoHeight
              rowHeight={25}
              rows={rows}
              columns={columns}
              pageSize={12}
              rowsPerPageOptions={[12]}
              getRowId={(row) => row.id}
              sx={
                  {
                    fontSize: 12,
                    height: "max-content"
                  }
              }
            />
            </CardContent>
            <CardContent>
              <Card sx={{
                    width: 268
                  }}>
                  <CardHeader 
                  sx={{height: 20,
                      fontWeight: 'bold'
                    }}
                  title="Royal Russell Trivia"
                  titleTypographyProps={{variant:'h7' }}
                  />
                    <CardContent>
                      <Typography variant="body2">
                      How many games did Royal play in his rookie season?
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent sx={{width: 'auto'}}>
                        <Typography variant="body2" color="text.secondary" >
                        (A) Royal played just 3 games in the 2007-08 season due to a hip injury.
                        </Typography>
                      </CardContent>
                    </Collapse>
              </Card>
            </CardContent>

        </Grid>
        </Card>  
  )
}