import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stats from '../../../api/girardStats.jsx';
import backGround from '../../../image/5717846.jpeg';
import background1 from '../../../image/IMG_0178.jpeg';
import CardHeader from '@mui/material/CardHeader';


const Item = styled(Paper)(({ theme }) => ({
  backgroundimage: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Bobby = "https://content.justrightsteve.com/cards/pics/IMG_0173.jpeg"

export default function BasicGrid() {
  //const [expanded, setExpanded] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}
            sx={
              {
                backgroundImage: `url(${backGround})`
              }
            }>
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12} >
          <Item>
            <Card sx={
              {}
            } >
              <CardHeader sx={{ backgroundImage: `url(${background1})`, color: 'black', backGroundRepeat: "no-repeat" }}
                          title="Bob(Bobby) Girard | November 16, 1957 - December 26, 2021"
                          titleTypographyProps={
                            {
                              fontFamily: "cursive",
                              variant: "h5"
                             }
                          }
                          >
              </CardHeader>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={3}> 
          <Item sx={
            {
              maxWidth: 470
            }
          } >
            <Card sx={{
            }}>
              <CardMedia
                component="img"
                height="300"
                image={Bobby}
                sx={
                  {
                    maxWidth: "480px"
                  }
                }
              />
              <CardContent sx={{width: 'auto'}}>
                <Typography variant="body2">
                A UHL "OG", Bobby joined the UHL in 1984 and is considered a founding member.  Over a decades long career he made immeasurable contributions to the league including serving as treasure and web admin.
                </Typography>
                <Typography variant="body2" >
                  ------------------------------
                </Typography>
                <Typography variant="body2">
                There are no words to capture the incredible sadness I'm feeling tonight. I've known Bob for more than four decades. We met in classes at Wayne State University in 1977 and his outstanding sense of humor and warm personality made us fast friends. Once he learned I was running a "pick up style" hockey league at Berkley Arena, he was eager to join and quickly became a valued member of the Unorganized Hockey League --affectionately known as the UHL -- at Berkley Arena. And we couldn't have asked for a better Treasurer (imagine trying to extract league fees from 44 different guys!!). Over the years, we'd meet "off the ice" for meals to talk about our families and our spiritual development. You will be missed my friend. Our prayers for Linda and the entire Girard family. - Roger Willis
                </Typography>
              </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={9} sx={{
        }}>
          <Item sx={
            {
              maxWidth: 750
              
            }
          } >
            <Grid container spacing={1}
                  sx={{}}>
              <Grid item xs={12} sm={12} md={12}>
                <Item sx={{ background: '#ececec' }} >
                  <Stats />
                </Item>
              </Grid>
             </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
