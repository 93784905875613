import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import API_ENDPOINTS from './config.js'

const ODD_OPACITY = 0.2;


const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

//var rows = require('./json/schedule.json');

const columns = [
      { field: 'Date',  width: '100', type: Date, sortComparator: (v1, v2) => new Date(v1) - new Date(v2) },
      { field: 'Home',  width: '110' },
      { field: 'Away',  width: '110' },
      { field: 'Time',  width: '100' },
      { field: 'Ref1',  width: '150' },
      { field: 'Ref2',  width: '150' },
      { field: 'Score', headerName: 'Final Score', width: '160', renderCell: (params) => ( 
          <Link to={`${params.row.GameLink}`} >
            {params.row.Score}
          </Link>       
        ),
      },
  ];

  export default function Content() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      // Fetch player data from the API endpoint
      setLoading(true);    
      // Fetch data from the API
      axios.get(API_ENDPOINTS.getSchedule)
        .then(response => {
          setRows(response.data); // Store the fetched data in the state
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    return (
    <div style={{ border: "none", boxShadow: "none", height: 700, width: '100%'}}>
    <StripedDataGrid
        rowHeight={25}
        rows={rows}
        loading={loading}
        columns={columns}
        rowsPerPageOptions={[12]}
        getRowId={(row) => row.id}
        components={{ 
          Toolbar: GridToolbar
       }}
        getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        sx={{}}
        style={{ border: "none", boxShadow: "none" }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'Date',
                sort: 'asc'
              }
            ]
          }
        }}
    />
    </div>

  )
}