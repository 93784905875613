import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Schedule from '../api/getScheduleBlock.jsx';
import Alert from '../component/newsAlert.jsx';
//import SeasonCalendar from '../component/SeasonCalendar.jsx'
//import Schedule from '../api/getScheduleShort.jsx';
import Standings from '../api/getStandingsShort'
//const detroitJersey = "https://content.justrightsteve.com/IMG_3282.png"
export default function StylingHeaderGrid() { 
  const [games, setGames] = useState([]);

  useEffect(() => {
    axios.get('https://g4pldhklgb.execute-api.us-east-1.amazonaws.com/prod/schedule')
      .then(response => {
        setGames(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

    return (
      <Card >
        <CardHeader sx={{color: 'black'}}
        title="News and Information"
        />
        <CardContent>
          <Alert/>
        </CardContent>
        <CardContent>
          <Standings/>
        </CardContent>
         <CardContent>
          <Schedule games={games}/>
        </CardContent>
      </Card>
    );
  }