import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Rules from '../api/rulesOfPlay.jsx';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  return (
    <Box sx={
              {
                maxWidth: "1183px"
              }
    }
    >
      <Grid container 
            spacing={1} 
            padding={1}
            sx={
              {
                position: "relative"
              }
            } >
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12}>
          <Item sx={
          {
            maxWidth: "1065px",
            background: '#ececec'         
          }
        }><Rules/></Item>
        </Grid>
      </Grid>
    </Box>
  );
}