// config.js
const ENV = "prod"
const API_BASE_URL = `https://g4pldhklgb.execute-api.us-east-1.amazonaws.com/${ENV}`;
// Replace with your actual API base URL

const API_ENDPOINTS = {
  getArticles: `${API_BASE_URL}/get-news`,
  getPlayers:    `${API_BASE_URL}/players`,
  getGoalies:  `${API_BASE_URL}/goalies`,
  getPlayers_deprecated: `${API_BASE_URL}/get-players`,
  getGoalies_deprecated: `${API_BASE_URL}/get-goalies`,
  getStandings: `${API_BASE_URL}/standings`,
  getSchedule: `${API_BASE_URL}/schedule`
  // Add more API endpoints as needed
};

export default API_ENDPOINTS;