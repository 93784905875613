import React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Schedule from '../api/getSchedule.jsx';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function StylingHeaderGrid() {
  
  return (
    <Box sx={{ maxWidth: "1183px",
               background: '#ececec'
            }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{ width: 'auto', padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} sx={{}}>
          <Item sx={{}}>
            <Schedule/>
          </Item>
        </Grid>
      </Grid>
    </Box>

  );
}
