import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from './config.js';
// Assuming you have a ProgressBar component
import ProgressBar from './ProgressBar.jsx';

const NewsFeed = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.getArticles, {
        onDownloadProgress: (progressEvent) => {
          // Calculate the loading progress
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setLoadingProgress(progress);
        },
      })
      .then((response) => {
        setArticles(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h1>League Charter</h1>
      {loading ? (
        <ProgressBar progress={loadingProgress} />
      ) : (
        articles.map((article) => (
          <div key={article.id} className="news-item">
            <div className="content-container">
              <p>{article.content}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const App = () => {
  return (
    <div>
      <NewsFeed />
    </div>
  );
};

export default App;
