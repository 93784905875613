import React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function getFullName(params) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}

function getCurrentSeason(params) {
  return params.row.seasons.find(season => season.id === "1") || {};
}

function getGamesPlayed(params) {
  const currentSeason = getCurrentSeason(params);
  return `${currentSeason.GP || ''}`;
}

function getGamesSubbed(params) {
  const currentSeason = getCurrentSeason(params);
  return `${currentSeason.GS || ''}`;
}

function getGoals(params) {
  //const currentSeason = getCurrentSeason(params);
  //return `${currentSeason.G || ''}`;
  const goals = parseInt(params.row?.seasons?.[0]?.G, 10);
  return !isNaN(goals) ? goals : 0;
}

function getAssists(params) {
  const currentSeason = getCurrentSeason(params);
  return `${currentSeason.A || ''}`;
}

function getPoints(params) {
  const currentSeason = getCurrentSeason(params);
  return Number(currentSeason.G || 0) + Number(currentSeason.A || 0);
}

function getGameWinners(params) {
  const currentSeason = getCurrentSeason(params);
  return `${currentSeason.GWG || ''}`;
}

function getPenaltyMinutes(params) {
  const currentSeason = getCurrentSeason(params);
  return `${currentSeason.PIM || ''}`;
}

const columns = [
  { field: 'Name',  width: '160', valueGetter: getFullName },
  { field: 'GP',    width: '60',  valueGetter: getGamesPlayed },
  { field: 'GS',    width: '60',  valueGetter: getGamesSubbed },
  { field: 'G',     width: '60',  valueGetter: getGoals },
  { field: 'A',     width: '60',  valueGetter: getAssists },
  { field: 'PTS',   width: '60',  valueGetter: getPoints, type: 'number', align: 'left' },
  { field: 'GWG',   width: '60',  valueGetter: getGameWinners },
  { field: 'PIM',   width: '60',  valueGetter: getPenaltyMinutes }  
];

export default function Content(props) {
  const { playersData } = props;

  // Filter out players that don't have information for season 1
  const filteredPlayersData = playersData.filter(player => {
    return player.seasons.some(season => season.id === "1");
  });
  
  return (
    <Box>
      <Card>
        <CardHeader sx={{ color: 'black' }} title="Player Stats" />
      </Card>
      <div style={{ height: 400, width: '100%'}}>
        <StripedDataGrid
          rowHeight={26}
          rows={filteredPlayersData}
          columns={columns}
          rowsPerPageOptions={[12]}
          getRowId={(row) => row.id}
          components={{ 
            Toolbar: GridToolbar
          }}
          getRowClassName={(params) =>
            params.index % 2 === 0 ? 'even' : 'odd'
          }
          sx={{}}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'PTS',
                  sort: 'desc'
                }
              ]
            }
          }}
        />
      </div>
    </Box>
  );
}
