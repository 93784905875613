import React, { useState, useEffect }from 'react';
import axios from 'axios';
//import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
//import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Standings from '../api/getStandings.jsx';
import PlayerStats from '../api/getPlayerStats.jsx';
import GoalieStats from '../api/getGoalieStats.jsx';
import API_ENDPOINTS from '../api/config.js'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function StylingHeaderGrid() {
  const [playersData, setPlayersData ] = useState([]);
  const [goaliesData, setGoaliesData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetching players data
    setLoading(true); 
    axios.get(API_ENDPOINTS.getPlayers)
        .then(response => {
            setPlayersData(response.data);
        })
        .catch(error => {
            console.error("Error fetching players:", error);
        })
        .finally(() => {
          setLoading(false);
        });
  
    // Fetching goalies data
    setLoading(true); 
    axios.get(API_ENDPOINTS.getGoalies)
        .then(response => {
        // Filter goalies who have stats for season 1
        const filteredGoalies = response.data.filter(goalie => 
        goalie.seasons.some(season => season.id === "1")
      );
            setGoaliesData(filteredGoalies);
        })
        .catch(error => {
            console.error("Error fetching goalies:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    // Add more fetch calls for other APIs as needed
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Simple loading text, can be replaced with a spinner or other indicator
  }
  
  return (
    <Box sx={{ maxWidth: "1183px",
               background: '#ececec'
            }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{ width: 'auto', padding: 2 }}>
        <Grid item xs={12} sm={12} md={12} sx={{}}>
          <Item sx={{ maxWidth: 1024 }}>
            <Standings/>
          </Item>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{ width: 'auto', padding: 2 }}>
        <Grid item xs={12} sm={12} md={7.9} sx={{}}>
          <Item sx={{ maxWidth: 775 }}>
            <PlayerStats
              playersData={playersData}
            />
          </Item>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{ width: 'auto', padding: 2 }}>
        <Grid item xs={12} sm={12} md={7.9} sx={{}}>
          <Item sx={{ maxWidth: 775 }}>
            <GoalieStats
              goaliesData={goaliesData}
            />
          </Item>
        </Grid>
      </Grid>
    </Box>



  );
}
