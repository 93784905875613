import React from 'react';
import { DataGrid, gridClasses} from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function getFullName(params) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}

function getGamesPlayed(params) {
  // Ensure that we have a seasons array and it has at least one element
  if (params.row.seasons && params.row.seasons.length > 0) {
    // Parse the GP value to a number, ensuring that it exists and is a number
    const gp = parseInt(params.row.seasons[0].GP, 10);
    // Check if gp is a number, otherwise return 0
    return !isNaN(gp) ? gp : 0;
  }
  return 0; // Default to 0 if seasons data is not available
}

function getWins(params) {
  const wins = parseInt(params.row?.seasons?.[0]?.W, 10);
  return !isNaN(wins) ? wins : 0;
}

function getLosses(params) {
  const losses = parseInt(params.row?.seasons?.[0]?.L, 10);
  return !isNaN(losses) ? losses : 0;
}

function getTies(params) {
  const ties = parseInt(params.row?.seasons?.[0]?.T, 10);
  return !isNaN(ties) ? ties : 0;
}

function getShutouts(params) {
  const shutouts = parseInt(params.row?.seasons?.[0]?.SO, 10);
  return !isNaN(shutouts) ? shutouts : 0;
}

function getGoalsAgainst(params) {
  const goalsAgainst = parseInt(params.row?.seasons?.[0]?.GA, 10);
  return !isNaN(goalsAgainst) ? goalsAgainst : 0;
}

function getGoalsAgainstAverage(params) {
  const gaa = parseFloat(params.row?.seasons?.[0]?.GAA);
  return !isNaN(gaa) ? gaa.toFixed(2) : '0.00';
}

const columns = [
  { field: 'Name',  width: '160',   valueGetter: getFullName },
  { field: 'GP',    width: '60',    valueGetter: getGamesPlayed }, 
  { field: 'W',     width: '60',    valueGetter: getWins },
  { field: 'L',     width: '60',    valueGetter: getLosses },
  { field: 'T',     width: '60',    valueGetter: getTies },
  { field: 'SO',    width: '60',    valueGetter: getShutouts },
  { field: 'GA',    width: '60',    valueGetter: getGoalsAgainst },
  { field: 'GAA',   width: '100',   valueGetter: getGoalsAgainstAverage }
]

export default function Content(props) {
  const { goaliesData } = props;
  return (
    <Box>
      <Card>
        <CardHeader sx={{color: 'black'}}
            title="Goalie Stats"
          />
      </Card>
      <div style={{ height: 185, width: '100%'}}>
      <StripedDataGrid
          rowHeight={26}
          rows={goaliesData}
          columns={columns}
          rowsPerPageOptions={[12]}
          getRowId={(row) => row.id}
          //getRowId={(row) => row._id.$oid}
          getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          sx={{}}
          hideFooter={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'GAA',
                  sort: 'asc'
                }
              ]
            }
          }}
      />
      </div>
    </Box>

  )
}