import React from 'react';
//import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
//import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//import Typography from '@mui/material/Typography';

const RulesOfPlay = () => {
  return (
    <Card sx={{
        height: "900px"
    }}>
      <CardContent>
      <div>
      <h1>Rules of Play</h1>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vQaGptlX5BYEpVXNuwWOT-aHRdJu7niwrJz-xneJOiqZ2MUhkYXE1maQjszLTauhq1dlb-5jbc2auZH/pub" // Replace with your published URL
        width="100%"
        height="900px"
        style={{ border: 'none' }}
        title="Rules of Play"
      ></iframe>
    </div>
      </CardContent>
    </Card> 
  );
}

export default RulesOfPlay;