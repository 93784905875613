import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress"
        style={{ width: `${progress}%`, transition: 'width 0.3s' }}
      />
    </div>
  );
};

export default ProgressBar;
