import React, { useState, useEffect }from 'react';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import '../css/box.css'

const rows = require('../api/json/players.json');
console.log(rows);

const players = (process.env.REACT_APP_PLAYERS_API);

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[300],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const columns = [
    { field: 'firstName', width: '100'},
    { field: 'lastName', width: '100'},
    { field: 'email', width: '250'},
    { field: 'phoneNum', width: '150'}
  ]

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  margin: 10,
  color: theme.palette.text.secondary,
}));

export default function Content() {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    fetch(players)
      .then((response) => response.json())
      .then((data) => setTableData(data))

  }, [])

  console.log(tableData)
  
  return (
    <Box sx={{ maxWidth: "1065px",
               background: '#ececec'
            }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{ width: 'auto', padding: 2 }}>
        <Grid item xs={12} sm={12} md={7.75} sx={{}}>
          <Item sx={{}}>
            <div style={{ height: 400, width: '100%' }}>
              <StripedDataGrid
                rowHeight={25}
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[12]}
                getRowId={(row) => row._id}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
              />            
              </div>
          </Item>
        </Grid>
      </Grid>
    </Box>



  )
}