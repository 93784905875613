import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { useParams, useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../api/config.js';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const home = [
  { field: "name", width: '150' },
  { field: "pos", width: '50' },
  { field: "no", width: '50' },
  { field: "g", width: '50' },
  { field: "a", width: '50' },
  { field: "pts", width: '50' },
  { field: "pim", width: '50' }
]

const away = [
  { field: "name", width: '150' },
  { field: "pos", width: '50' },
  { field: "no", width: '50' },
  { field: "g", width: '50' },
  { field: "a", width: '50' },
  { field: "pts", width: '50' },
  { field: "pim", width: '50' }
]

const goals = [
  { field: "Time", width: '65' },
  { field: "Team", width: '75' },
  { field: "ScoredBy", width: '135' },
  { field: "Asst1", width: '135' },
  { field: "Asst2", width: '135' }
]

const penalties = [
  { field: "Time", width: '65' },
  { field: "Team", width: '75' },
  { field: "Player", width: '135' },
  { field: "Infraction", width: '135' },
  { field: "PIM", width: '50' }
]

export default function BasicGrid() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const handleClick = () => {
    // 👇️ replace set to true
    navigate('/Schedule', { replace: true });
  };

  useEffect(() => {
    // Fetch player data from the API endpoint
    setLoading(true);
    // Fetch data from the API
    axios.get(API_ENDPOINTS.getSchedule)
      .then(response => {
        setRows(response.data); // Store the fetched data in the state
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Box sx={
      {
        maxWidth: "1183px"
      }
    }
    >
      <Grid container
        spacing={1}
        padding={1}
        sx={
          {
            position: "relative"
          }
        } >
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12}  >
          <div>
            <button onClick={handleClick}>Back to Schedule</button>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} ld={12} xl={12}  >
          <Typography>
            Game Information
          </Typography>
          <Typography>
            Date/Time: {rows[(id)]?.Date} @ {rows[(id)]?.Time}
          </Typography>
          <Typography>
            Final Score: {rows[(id)]?.Score}
          </Typography>
          <Typography>
            Referees: {rows[(id)]?.Ref1}/{rows[(id)]?.Ref2}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} ld={6} xl={6}  >
          <Item
            style={{ border: "none", boxShadow: "none" }}
            sx={
              {
                maxWidth: "500px"
              }
            }>
            <Typography variant="h4">
              {rows[(id)]?.Home} Stats
            </Typography>
            <DataGrid
              autoHeight
              rowHeight={25}
              rows={rows[(id)]?.Lineups.Home}
              loading={loading}
              columns={home}
              pageSize={12}
              density='compact'
              rowsPerPageOptions={[12]}
              getRowId={(row) => row.id}
              sx={
                {
                  fontSize: 12,
                  height: "max-content"
                }
              }
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} ld={6} xl={6}  >
          <Item
            style={{ border: "none", boxShadow: "none" }}
            sx={
              {
                maxWidth: "500px"
              }
            }>
            <Typography variant="h4">
              {rows[(id)]?.Away} Stats
            </Typography>
            <DataGrid
              autoHeight
              rowHeight={25}
              rows={rows[(id)]?.Lineups.Away}
              columns={away}
              pageSize={12}
              density='compact'
              rowsPerPageOptions={[12]}
              getRowId={(row) => row.id}
              sx={
                {
                  fontSize: 12,
                  height: "max-content"
                }
              }
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} ld={6} xl={6}  >
          <Item
            style={{ border: "none", boxShadow: "none" }}
            sx={
              {
                maxWidth: "1183px"
              }
            }>
            <Typography variant="h4">
              Goals
            </Typography>
            <DataGrid
              autoHeight
              rowHeight={25}
              rows={rows[(id)]?.Goals}
              columns={goals}
              pageSize={12}
              density='compact'
              rowsPerPageOptions={[12]}
              getRowId={(row) => row.id}
              sx={
                {
                  fontSize: 12,
                  height: "max-content"
                }
              }
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6} ld={6} xl={6}  >
          <Item
            style={{ border: "none", boxShadow: "none" }}
            sx={
              {
                maxWidth: "1180px"
              }
            }>
            <Typography variant="h4">
              Penalties
            </Typography>
            <DataGrid
              autoHeight
              rowHeight={25}
              rows={rows[(id)]?.Penalties}
              columns={penalties}
              pageSize={12}
              rowsPerPageOptions={[12]}
              density='compact'
              getRowId={(row) => row.id}
              sx={
                {
                  fontSize: 12,
                  height: "max-content"
                }
              }
            />
          </Item>
        </Grid>
      </Grid>
    </Box>
  )

}