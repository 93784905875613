import React, { useEffect, useState }from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import NewYork from '../api/getNyInfo.jsx';
import Detroit from '../api/getDetInfo.jsx';
import Chicago from '../api/getChiInfo.jsx';
import Boston from '../api/getBosInfo.jsx';
import API_ENDPOINTS from '../api/config.js'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  const [playersData, setPlayersData] = useState([]);
  const [goaliesData, setGoaliesData] = useState([]);
  console.log(playersData)
  console.log(goaliesData)
useEffect(() => {
  // Fetching players data
  axios.get(API_ENDPOINTS.getPlayers)
      .then(response => {
          setPlayersData(response.data);
      })
      .catch(error => {
          console.error("Error fetching players:", error);
      });

  // Fetching goalies data
  axios.get(API_ENDPOINTS.getGoalies)
      .then(response => {
          setGoaliesData(response.data);
      })
      .catch(error => {
          console.error("Error fetching goalies:", error);
      });
  // Add more fetch calls for other APIs as needed
}, []);
  //const [] = React.useState(false);
 
  return (
    <Box sx={{ maxWidth: 1183, background: '#ececec' }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1}} sx={{ maxWidth: 1200, padding: 1 }}>
        <Grid item xs={12} sm={12} md={6} sx={{}}>
          <Item sx={{ maxWidth: 512 }}>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader sx={{background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(0,56,168,1) 35%, rgba(209,192,177,1) 100%)', color: 'white', height: 10, width: 'auto'}}
                title="New York - Tom Rolands"
                />
                <CardContent>
                  <NewYork 
                    playersData={playersData}
                    goaliesData={goaliesData}
                  />
                </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Item sx={{ maxWidth: 512 }}>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader sx={{background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(206,17,38,1) 35%, rgba(209,192,177,1) 100%)', color: 'white', height: 10, width: 'auto'}}
                title="Detroit - Tom Berlin"
                />
              <CardContent>
                <Detroit 
                  playersData={playersData}
                  goaliesData={goaliesData}
                />
              </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Item sx={{ maxWidth: 512 }} >
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader 
                sx={{ background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(0,0,0,1) 100%)', color: 'black', height: 10, width: 'auto', fontFamily: 'Chilanka' }}
                title="Chicago - Mike Hawkins"
              />
              <CardContent>
                <Chicago 
                  playersData={playersData}
                  goaliesData={goaliesData}
                />
              </CardContent>
            </Card>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Item sx={{ maxWidth: 512 }}>
          <Card style={{ border: "none", boxShadow: "none" }}>
              <CardHeader sx={{background: 'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,184,16,1) 35%, rgba(209,192,177,1) 100%)', color: 'white', height: 10, width: 'auto'}}
                title="Boston - Denny Savage"
                />
                <CardContent>
                  <Boston   
                    playersData={playersData}
                    goaliesData={goaliesData}
                  />
                </CardContent>
            </Card>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
