import React from "react";
import { Typography} from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const ImageUrl = "https://content.justrightsteve.com/faceoff.webp";

function GroupPhotoComponent() {
  return (
    <Card sx={{ maxWidth: 810 }}>
      <CardHeader
        sx={{ color: 'black', fontSize: '28px', fontWeight: 'bold' }}
        title="Welcome Back!!!"
      />
      <CardMedia
        component="img"
        height="200"
        image={ImageUrl}
      />
<CardContent>
  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
    <br />Hockey is back, gang!
    <br />
    <br />The 2024-2025 season starts on Sunday, September 8. Game times:
    <br />
    7:45 PM and 8:45 PM.
    <br />
    <br />New for this season:
    <br />
    <br />1. The two-line pass rule is gone!
    <br />
    <br />2. Harry's Corner has moved to X (formerly Twitter). Find him at @harryscorn57503.
  </Typography>
</CardContent>
    </Card>
  );
}


export default GroupPhotoComponent;
